export const genres = {
    Artifact: true,
    Collapsed: true,
    Container: true,
    DAG: true,
    HTTP: true,
    Pod: true,
    Plugin: true,
    Retry: false,
    Skipped: true,
    StepGroup: false,
    Steps: true,
    Suspend: true,
    TaskGroup: false
};
