import * as React from 'react';

import {Modal} from '../shared/components/modal/modal';
import {SurveyButton} from '../shared/components/survey-button';

import './new-version-modal.scss';

/**
 * The intention of this modal is to encourage update of new features.
 */
export function NewVersionModal({version, dismiss}: {version: string; dismiss: () => void}) {
    return (
        <Modal dismiss={dismiss}>
            <div className='new-version-modal-banner'>
                <i className='fa fa-arrow-circle-up' />{' '}
            </div>
            <h4 className='new-version-modal-title'>
                Could it be you're quite new to Argo?
            </h4>
            <h5>Did you know you can:</h5>
            <ul className='new-version-modal-bullets'>
                <li>
                    <a href='https://argo-workflows.readthedocs.io/en/latest/artifact-visualization/?utm_source=argo-ui' target='_blank' rel='noreferrer'>
                        Visualize artifact{' '}
                    </a>
                    in the UI
                </li>
                <li>
                    Provide{' '}
                    <a href='https://argo-workflows.readthedocs.io/en/latest/intermediate-inputs/?utm_source=argo-ui' target='_blank' rel='noreferrer'>
                        parameter input{' '}
                    </a>
                    in the middle of the workflow
                </li>
                <li>Filter by date and time in the UI</li>
                <li>Connect with the workflow using lifecycle hooks.</li>
                <li>Pause steps in your workflow with Debug pause.</li>
                <li>
                    Writing workflows <b>without YAML</b> using{' '}
                    <a href='https://argo-workflows.readthedocs.io/en/latest/client-libraries/?utm_source=argo-ui' target='_blank' rel='noreferrer'>
                        Python and Java SDKs
                    </a>
                    .
                </li>
                <li>
                    Run fan-out workflows based on bucket contents using{' '}
                    <a href='https://argo-workflows.readthedocs.io/en/latest/data-sourcing-and-transformation/?utm_source=argo-ui' target='_blank' rel='noreferrer'>
                        data templates
                    </a>
                    .
                </li>
            </ul>
        </Modal>
    );
}
